<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Créditos Finalizados</v-card-title>
        <DataTableCreditState
          :rol="rolUserLogged"
          :state-id="stateId"
          :loading-text="'Cargando Créditos Rechazados'"
        ></DataTableCreditState>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTableCreditState from './datatables/DataTableCreditState.vue'

export default {
  components: {

    DataTableCreditState,

  },
  data: () => ({
    rolUserLogged: null,
    stateId: 4,
  }),
  created() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },

}
</script>
